<template>
  <div id="Header">
    <div class="header_center">
      <div class="logoBox">
        <img src="@/assets/imgs/logo.jpg" alt="">
      </div>
      <el-menu :default-active="activeIndex" class="el-menu-header" mode="horizontal" >
        <el-menu-item index="1" @click="$router.push('/')">首页<span></span></el-menu-item>
        <el-submenu index="2" popper-class="el-menu-second">
          <template slot="title">产品</template>
          <el-menu-item index="2-1" @click="$router.push('/diting')">海河·谛听大模型</el-menu-item>
          <el-menu-item index="2-2" @click="$router.push('/ai')">慧言AI平台</el-menu-item>
          <el-menu-item index="2-3" @click="$router.push('/meeting')">智能会议系统</el-menu-item>
          <el-menu-item index="2-4" @click="$router.push('/privatization')">智能语音私有化部署</el-menu-item>
<!--          <el-menu-item index="2-5" @click="goWaibu(9)">慧言翻译机</el-menu-item>-->
          <el-menu-item index="2-5" @click="$router.push('/nmt')">慧言翻译机</el-menu-item>
<!--          <el-menu-item index="2-5" @click="$router.push('/sdk')">智能语音移动端离线SDK</el-menu-item>-->
        </el-submenu>
        <el-submenu index="3" popper-class="el-menu-second">
          <template slot="title">解决方案</template>
          <el-menu-item index="3-1" @click="$router.push('/screen')">会议大屏解决方案</el-menu-item>
          <el-menu-item index="3-2" @click="$router.push('/edu')">智慧教育解决方案</el-menu-item>
          <el-menu-item index="3-3" @click="$router.push('/interpreting')">智慧同传解决方案</el-menu-item>
          <el-menu-item index="3-4" @click="$router.push('/ppp')">公检法场景解决方案</el-menu-item>
        </el-submenu>
        <el-menu-item index="8" @click="goWaibu(8)">海河谛听<span></span></el-menu-item>
        <el-menu-item index="6" @click="goWaibu(6)">AI平台<span></span></el-menu-item>
        <el-menu-item index="7" @click="goWaibu(7)">慧言会听<span></span></el-menu-item>
        <el-submenu index="4" popper-class="el-menu-second">
          <template slot="title">关于我们</template>
          <el-menu-item index="4-1" @click="$router.push('/introduction')">公司简介</el-menu-item>
          <el-menu-item index="4-2" @click="$router.push('/news')">新闻中心</el-menu-item>
          <el-menu-item index="4-3" @click="$router.push('/career')">加入我们</el-menu-item>
        </el-submenu>
        <el-menu-item index="5" @click="$router.push('/contact')">联系我们<span></span></el-menu-item>
      </el-menu>
    </div>
  </div>
</template>

<script>
export default {
  name: "hyHeader",
  data() {
    return {
      activeIndex: '',
      noLogin: true,
    };
  },
  watch: {
    "$route": {
      handler(route){
        const that = this
        if (route.path == '/') {
          that.activeIndex = '1'
        } else if (route.path == '/diting') {
          that.activeIndex = '2-1'
        } else if (route.path == '/ai') {
          that.activeIndex = '2-2'
        } else if (route.path == '/meeting') {
          that.activeIndex = '2-3'
        } else if (route.path == '/privatization') {
          that.activeIndex = '2-4'
        } else if (route.path == '/nmt') {
          that.activeIndex = '2-5'
        } else if (route.path == '/screen') {
          that.activeIndex = '3-1'
        } else if (route.path == '/edu') {
          that.activeIndex = '3-2'
        } else if (route.path == '/interpreting') {
          that.activeIndex = '3-3'
        } else if (route.path == '/ppp') {
          that.activeIndex = '3-4'
        } else if (route.path == '/introduction') {
          that.activeIndex = '4-1'
        } else if (route.path == '/news') {
          that.activeIndex = '4-2'
        } else if (route.path == '/career') {
          that.activeIndex = '4-3'
        } else if (route.path == '/contact') {
          that.activeIndex = '5'
        }
      },
    },
  },
  mounted() {
    if (this.$route.path == '/') {
      this.activeIndex = '1'
    } else if (this.$route.path == '/diting') {
      this.activeIndex = '2-1'
    } else if (this.$route.path == '/ai') {
      this.activeIndex = '2-2'
    } else if (this.$route.path == '/meeting') {
      this.activeIndex = '2-3'
    }  else if (this.$route.path == '/privatization') {
      this.activeIndex = '2-4'
    } else if (this.$route.path == '/nmt') {
      this.activeIndex = '2-5'
    } else if (this.$route.path == '/screen') {
      this.activeIndex = '3-1'
    } else if (this.$route.path == '/edu') {
      this.activeIndex = '3-2'
    } else if (this.$route.path == '/interpreting') {
      this.activeIndex = '3-3'
    } else if (this.$route.path == '/ppp') {
      this.activeIndex = '3-4'
    } else if (this.$route.path == '/introduction') {
      this.activeIndex = '4-1'
    } else if (this.$route.path == '/news') {
      this.activeIndex = '4-2'
    } else if (this.$route.path == '/career') {
      this.activeIndex = '4-3'
    } else if (this.$route.path == '/contact') {
      this.activeIndex = '5'
    }
  },
  methods: {
    goWaibu(val) {
      if (val == 6) {
        window.open('https://ai.huiyan-tech.com/','_blank')
      } else if (val == 7) {
        window.open('https://huiting.huiyan-tech.com','_blank')
      } else if (val == 8) {
        window.open('https://diting.huiyan-tech.com/#/login','_blank')
      }
      // else if (val == 9) {
      //   window.open('./pdf/慧言翻译机.pdf','_blank')
      // }
    },
  },
};
</script>

<style lang="less" scoped>
#Header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100px;
  background: #FFFFFF;
}
.header_top {
  width: 100%;
  height: 20px;
  margin: 0 auto;
  background: #F1F3F4;
  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 1200px;
    line-height: 20px;
    margin: 0 auto;
    a {
      margin-left: 30px;
    }
  }
}
.header_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 1200px;
  height: 80px;
  margin: 0 auto;
}
.logoBox {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 154px;
  height: 82px;
  img {
    width: 100%;
  }
}
.el-menu-header {
  display: flex;
  align-items: center;
  height: 100%;
  background: transparent;
  border: 0;
  .el-menu-item {
    padding: 0;
    margin: 0 20px;
    text-align: center;
    border: none!important;
    color: #0D2236;
    font-size: 18px;
    span {
      display: flex;
      justify-content: center;
      width: 22px;
      height: 4px;
      margin: 5px auto 0;
      border-radius: 2px;
    }
  }
  .el-submenu {
    /deep/ .el-submenu__title {
      color: #0D2236;
      font-size: 18px;
    }
  }
  .el-menu-item.is-active {
    color: #006EFE;
  }
  .el-menu-item.is-active span {
    background: #006EFE;
  }
  .el-menu-item:hover {
    color: #006EFE;
  }
  .el-menu-item:hover span {
    background: #006EFE;
  }
}
</style>
<style lang="less">
.el-menu-second {
  top: 100px!important;
  padding: 24px 40px;
  background: rgba(46,91,234,0.8);
  .el-menu {
    min-width: 80px;
    padding: 0;
    margin: 0;
    background: initial;
    box-shadow: none;
    .el-menu-item {
      padding: 0;
      margin: 0;
      background: initial;
      color: #fff;
      font-size: 14px;
    }
    .el-menu-item:hover {
      color: #EF9344;
    }
    .el-menu-item.is-active {
      color: #EF9344;
    }
  }
}
.el-submenu__title i {
  margin-top: -6px!important;
  margin-left: 5px!important;
  &:before {
    content: "\e790";
    font-size: 18px;
    color: #000;
  }
}
</style>
