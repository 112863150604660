<template>
  <div class="container">
    <app-header />
    <router-view />
    <app-footer />
  </div>
</template>
<script>
import AppHeader from '@/components/Header'
import AppFooter from '@/components/Footer'
export default {
  name: "home",
  data() {
    return {}
  },
  components: {
    AppHeader,
    AppFooter
  }
};
</script>
